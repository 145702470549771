import React from "react";
import { Helmet } from "react-helmet";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import get from "lodash/get";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SiteNav from "../components/SiteNav";
import SiteFooter from "../components/SiteFooter";

import Video from "../components/Video";
import ReadMoreHTML from "../components/ReadMoreHTML";
import PostFeaturedImage from "../components/PostFeaturedImage";
import ContentTheme from "../components/ContentTheme";
import InsetContent from "../components/InsetContent";
import { TagList, Tag } from "../components/TagList";
import Sections from "../components/Headers/Sections";

const PostWrapper = styled(InsetContent)`
	margin-top: 2em;
	margin-bottom: 2em;
`;

const Article = styled.article`
	background: ${(props) => props.theme.contentBackground};
	border-top: ${(props) => props.theme.contentTopBorder};
`;

class BlogPostTemplate extends React.Component {
	render() {
		const post = this.props.data.markdownRemark;
		const siteTitle = get(this.props, "data.site.siteMetadata.title");
		const { previous, next } = this.props.pageContext;
		const emptyHeader = get(this, "props.data.header");
		const permalink = post.fields.slug;
		return (
			<Layout>
				<ContentTheme key={post.id} layout={post.frontmatter.layout}>
					<div>
						<Article className="h-entry">
							<Helmet
								title={`${post.frontmatter.title} - ${siteTitle}`}
							/>
							{permalink && (
								<Helmet key="permalink">
									<link
										rel="self"
										href={
											"https://stevestreza.com" +
											permalink
										}
									/>
								</Helmet>
							)}
							{post.frontmatter.image && (
								<div>
									<Sections floating={true} />
									<PostFeaturedImage
										offsetTop="1em"
										image={
											post.frontmatter.image
												.childImageSharp.gatsbyImageData
										}
										title={post.frontmatter.title}
										date={post.frontmatter.date}
										dateISO8601={
											post.frontmatter.dateISO8601
										}
									/>
								</div>
							)}
							{!post.frontmatter.image && (
								<div>
									<SiteNav
										photo={this.props.data.photo.small}
									/>
									<PostFeaturedImage
										height={"7em"}
										image={
											emptyHeader.childImageSharp
												.gatsbyImageData
										}
									/>
									<Sections />
								</div>
							)}
							<PostWrapper
								className="e-content"
								hasImage={!!post.frontmatter.image}
							>
								{post.frontmatter.video_url && (
									<section>
										<Video
											url={post.frontmatter.video_url}
										/>
									</section>
								)}
								<ReadMoreHTML
									truncate={false}
									link={post.frontmatter.link}
									html={post.html}
								/>
								{post.frontmatter.tags && (
									<TagList>
										{post.frontmatter.tags.map((tag) => (
											<Tag key={tag} tag={tag} />
										))}
									</TagList>
								)}
							</PostWrapper>
						</Article>
						<SiteFooter />
					</div>
				</ContentTheme>
			</Layout>
		);
	}
}

export const query = graphql`
	query Page($slug: String!) {
		site {
			siteMetadata {
				title
				author
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			html
			fields {
				slug
			}
			frontmatter {
				title
				layout
				date(formatString: "MMMM DD, YYYY")
				dateISO8601: date(formatString: "YYYY-MM-DD HH:mm:ss.SSSZZ")
				video_url
				tags
				link
				image {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}

		header: file(relativePath: { eq: "header/header.png" }) {
			...ImageData
		}

		photo: file(relativePath: { eq: "photo.jpg" }) {
			small: childImageSharp {
				gatsbyImageData(layout: FIXED, width: 60, height: 60)
			}
		}
	}
`;

export default BlogPostTemplate;
